<template>
  <div class="bemVindo">
    <div class="bemVindo__container">
      <img class="bemVindo__container__hero" :src="bemVindoHero" alt="Seja bem vindo." />
      <div>
        <img class="bemVindo__container__logo"  :src="logoBranca" alt="Logo Tec Club" />
        <h1>SEJA BEM-VINDO (A) AO PROGRAMA TEC CLUB!</h1>
        <p>
          Ainda não terminou de preencher o seu cadastro? Complete agora mesmo
          todos os seus dados e ganhe <span>5 PONTOS</span> Deseja continuar?
        </p>
        <button @click="completarCadastro">COMPLETAR CADASTRO</button>
        <button class="btn-home" @click="IrParaHome">IR PARA HOME</button>
      </div>
    </div>
  </div>
</template>

<script>
import bemVindoHero from "@/assets/images/tema/bem-vindo-hero.png";
import logoBranca from "@/assets/images/tema/logo-tec.png";
export default {
  data() {
    return {
      bemVindoHero: bemVindoHero,
      logoBranca: logoBranca,
    };
  },

  methods: {
    fecharModalBemVindo() {
      this.$store.commit("interacoes/SET_MODAL_BEM_VINDO", {
        ativado: false,
        mensagem: "Bem Vindo",
        tipo: "",
      });
    },
    completarCadastro() {
      this.fecharModalBemVindo();
      this.$router.push("/meu-perfil");
    },
    IrParaHome() {
      this.fecharModalBemVindo();
      this.$router.push("/")
    }
  }
};
</script>

<style lang="scss" scoped>
.bemVindo {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #444444b0;
  @media(max-width: 768px) {
    padding: 0 15px;
  }

  &__container {
    // width: 1357px;
    width: 900px;
    height: 500px;
    background: $bg-header-cadastro;
    display: flex;
    justify-content: space-around;
    padding: 0 36px;
    position: relative;

    &__hero {
      width: 470px;
    }

    &__logo {
      width: 220px;
    }

    @media(max-width: 768px) {
      height: 625px;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      // padding: 22px 22px 32px 23px;
      margin: 0 15px;

      &__hero {
        width: 276px;
        height: 256px;
        order: 2;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @media(max-width: 768px) {
        align-items: center;
      }

      h1 {
        font-weight: bold;
        font-size: 25px;
        color: $branco;
        width: 350px;
        margin-top: 44px;
        margin-bottom: 9px;

        @media(max-width: 768px) {
          margin-top: 24px;
          font-size: 20px;
          text-align: center;
          width: 100%;
        }
      }

      p {
        font-weight: normal;
        font-size: 18px;
        line-height: 32px;
        color: $branco;
        width: 345px;
        span {
          font-weight: bold;
        }

        @media(max-width: 768px) {
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          text-align: center;
        }
      }

      .btn-home {
        background: transparent;
        color: $branco;
      }

      button {
        width: 350px;
        height: 49px;
        border-radius: 5px;
        border: none;
        background: $branco;
        font-weight: 600;
        font-size: 14px;
        color: $cor-laranja;
        cursor: pointer;
        margin-top: 20px;

        @media(max-width: 768px) {
          width: 302px;
          // position: absolute;
          bottom: 20px;
        }
      }
    }
  }
}
</style>